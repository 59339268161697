import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility'; // Corrige los íconos de Leaflet
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';

const MapComponent = ({ onCoordinatesChange }) => {
  const [position, setPosition] = useState({ lat: -17.7833, lng: -63.1821 }); // Coordenadas iniciales de Santa Cruz

  // Obtener ubicación del cliente usando la geolocalización
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setPosition({ lat: latitude, lng: longitude });
      }, () => {
        console.log('No se pudo obtener la ubicación.');
      });
    }
  }, []);

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const newCoords = e.latlng;
        setPosition(newCoords);
        onCoordinatesChange(newCoords); // Actualiza las coordenadas en el padre
      },
    });

    return (
      <Marker position={position}>
        <Popup>
          Coordenadas seleccionadas:
          <br />
          {`Lat: ${position.lat.toFixed(5)}, Lng: ${position.lng.toFixed(5)}`}
        </Popup>
      </Marker>
    );
  };

  return (
    <>
      {/* Contenedor del mapa */}
      <MapContainer
        center={position}
        zoom={13}
        style={{ height: '400px', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <LocationMarker />
      </MapContainer>
    </>
  );
};

export default MapComponent;
