import React, {useState} from "react";
import axios from "axios";
import { ServiciosDisponibles } from '../../../constants/ServiciosDisponibles';

const ClienteServicioEnProceso = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  latitud,
  longitud,
  usuarioAsignado
}) => {
  // Encontrar el servicio de limpieza
  const imagen_servicio = ServiciosDisponibles.find(
    (servicio) => servicio.name === tipoServicio
  );

  // Obtener la ruta de la imagen
  const imagen_select = imagen_servicio ? imagen_servicio.src : "https://placehold.co/600x400";

  return (
    <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <picture className="image-container">
        <img
          src={imagen_select}
          alt={tipoServicio}
          className="responsive-image w3-hover-opacity"
        />
      </picture>

      <div className="w3-container w3-white">
        
      <br/>
      <div className="footer-servicio">
        <p className="text-center">{usuarioAsignado === "" ? "Esperando un proveedor..." : "Solicitud Aceptada"}</p>
      </div>
      <br/>
        <p>
          <b>Tipo de Servicio: {tipoServicio}</b>
        </p>
        <p>Metros Cuadrados: {metrosCuadrados}</p>
        <p>Habitaciones: {habitaciones}</p>
        <p>Baños: {banos}</p>
        <p>Tengo Equipos y Suministros: {equiposSuministros ? "Sí" : "No"}</p>
        <p>Fecha y Horario Preferido: {fechaHorario}</p>
        <p>Presupuesto del Cliente: {presupuestoCliente}</p>
        <p>Servicios Adicionales: {serviciosAdicionales}</p>
        {latitud && longitud && (
            <p>
              <a
                href={`https://www.google.com/maps?q=${latitud},${longitud}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-gradient-to-r from-green-500 to-green-300 text-white text-sm font-semibold py-2 px-4 rounded-full shadow-md hover:from-green-600 hover:to-green-400 hover:shadow-lg transform hover:-translate-y-1 transition"
              >
                📍 Ver ubicación en el mapa
              </a>
            </p>
          )}

      </div>
    </div>
  );
};

export default ClienteServicioEnProceso;
