import React, {useState} from "react";
import axios from "axios";
import { WEB_SERVICES_URL } from '../../constants/Url';
import { ServiciosDisponibles } from '../../constants/ServiciosDisponibles';
import { useAlertContext } from "../../context/alertContext";

const Servicio = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
}) => {
  const { onOpen } = useAlertContext();
  // Encontrar el servicio de limpieza
  const imagen_servicio = ServiciosDisponibles.find(
    (servicio) => servicio.name === tipoServicio
  );

  // Obtener la ruta de la imagen
  const imagen_select = imagen_servicio ? imagen_servicio.src : "https://placehold.co/600x400";

  const [clicked, setClicked] = useState(false);

  const handleClickAceptarServicio = () => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL + `/limpieza/asignar/${id}`, { token: token })
    .then(response => {
      console.log(response.status);
      if (response.status === 204) {
        // Ejecutar acción cuando el código de estado es 200 o 201
        setClicked(true);
        onOpen("success", "Servicio aceptado.");
      } else {
        // Si la respuesta tiene otro código de estado, manejarlo aquí
        console.error('Respuesta inesperada:', response);
      }
    })
    .catch(error => {
      // Manejar errores de la solicitud
      setClicked(true);
      onOpen("error", "Ya se asignó el servicio a otro usuario.");
      // Aquí podrías manejar el error de la solicitud como un error de red, etc.
    });
    };

  return (
    !clicked && <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <picture className="image-container">
        <img
          src={imagen_select}
          alt={tipoServicio}
          className="responsive-image w3-hover-opacity"
        />
      </picture>

      <div className="w3-container w3-white">
        <p>
          <b>Tipo de Servicio: {tipoServicio}</b>
        </p>
        <p>Metros Cuadrados: {metrosCuadrados}</p>
        <p>Habitaciones: {habitaciones}</p>
        <p>Baños: {banos}</p>
        <p>Tengo Equipos y Suministros: {equiposSuministros ? "Sí" : "No"}</p>
        <p>Fecha y Horario Preferido: {fechaHorario}</p>
        <p>Presupuesto del Cliente: {presupuestoCliente}</p>
        <p>Servicios Adicionales: {serviciosAdicionales}</p>
      </div>

      <div className="footer-servicio">
        <button className="btn btn-custom" onClick={handleClickAceptarServicio}>Aceptar servicio</button>
      </div>
    </div>
  );
};

export default Servicio;
