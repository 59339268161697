import React, {useState} from "react";
import axios from "axios";
import { WEB_SERVICES_URL } from '../../constants/Url';
import InputStars from '../../forms/InputStars';
import { ServiciosDisponibles } from '../../constants/ServiciosDisponibles';

const ClienteServicioFinalizado = ({
  id,
  tipoServicio,
  metrosCuadrados,
  habitaciones,
  banos,
  equiposSuministros,
  fechaHorario,
  presupuestoCliente,
  serviciosAdicionales,
  calificacionCliente
}) => {
  // Encontrar el servicio de limpieza
  const imagen_servicio = ServiciosDisponibles.find(
    (servicio) => servicio.name === tipoServicio
  );

  // Obtener la ruta de la imagen
  const imagen_select = imagen_servicio ? imagen_servicio.src : "https://placehold.co/600x400";
  
  const [userRating, setUserRating] = useState(0);

  const handleRatingChange = (rating) => {
    setUserRating(rating);
  };
  
  const [clicked, setClicked] = useState(false);
  const handleClickCalificarCliente = () => {
    const token = localStorage.getItem('id_token');

    axios.put(WEB_SERVICES_URL+`/limpieza/calificarcliente/${id}`, { calificacionCliente: userRating, token: token })
      .then(response => {
        // Manejar la respuesta según sea necesario
        // console.log(response.data);
        setClicked(true);
    })
      .catch(error => {
        // Manejar errores de la solicitud
        console.error('Error al aceptar el servicio:', error);
      });
  };

  return (
    <div className="w3-third w3-container w3-margin-bottom card-servicios">
      <picture className="image-container">
        <img
          src={imagen_select}
          alt={tipoServicio}
          className="responsive-image w3-hover-opacity"
        />
      </picture>

      <div className="w3-container w3-white">
        <p>
          <b>Tipo de Servicio: {tipoServicio}</b>
        </p>
        <p>Metros Cuadrados: {metrosCuadrados}</p>
        <p>Habitaciones: {habitaciones}</p>
        <p>Baños: {banos}</p>
        <p>Tengo Equipos y Suministros: {equiposSuministros ? "Sí" : "No"}</p>
        <p>Fecha y Horario Preferido: {fechaHorario}</p>
        <p>Presupuesto del Cliente: {presupuestoCliente}</p>
        <p>Servicios Adicionales: {serviciosAdicionales}</p>
      </div>

      {calificacionCliente === "" && clicked === false  && <div className="footer-servicio">
        <InputStars onChange={handleRatingChange} />
        {/* <p>Tu calificación: {userRating}</p> */}
        <button className="btn-custom btn-calificar" onClick={handleClickCalificarCliente}>Calificar</button>
      </div>}
    </div>
  );
};

export default ClienteServicioFinalizado;
